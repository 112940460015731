var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"mycontainer"},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.mortgages.length)+" Applications ")]),_c('v-card-title',[_c('v-text-field',{attrs:{"label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-select',{attrs:{"items":['mortgage','nhf'],"item-text":"status","item-value":"status","label":"Filter By type"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.apiloading,"loading-text":"Loading... Please wait","headers":_vm.mortgage_headers,"items":_vm.mortgages,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.firstname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")]}},{key:"item.loan_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.loan_amount))+" ")]}},{key:"item.loan_tenure",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.loan_tenure)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.showSummary(item.slug)}}},[_vm._v("mdi-eye-circle-outline")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }